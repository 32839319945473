<template>
  <div>
    <!-- Filters -->
    <filter-job
      :countries="countries"
      :check-permission="checkPermission"
      @filter-query="handleFilters"
    />
    <!--/ Filters -->
    <b-card class="mb-0">
      <div>
        <b-row>
          <b-col v-if="checkPermission(['CREATE_JOBS', 'ALLOW_ALL'])">
            <div class="d-flex justify-content-end">
              <create-job-modal
                :countries="countries"
                :check-permission="checkPermission"
                :club-info="clubInfo"
                :color-primary-btn="colorPrimaryBtn"
                @successfully="getJobs"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="isLoading"
        opacity="1"
        :class="isLoading ? 'p-2 mb-1 mt-1' : ''"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-table
          ref="refUserListTable"
          class="position-relative mt-1"
          responsive
          :fields="headerTableJob"
          :items="jobList"
          primary-key="id"
          show-empty
          :empty-text="$t('generic.noRecordsFound')"
        >
          <!-- Column: club name -->
          <template #cell(club_name)="data">
            <span v-if="data.item.club != null">
              {{ data.item.club.club_name }}
            </span>
            <span v-else> N/A </span>
          </template>
          <!-- Column: created_at -->
          <template #cell(created_at)="data">
            <span v-if="data.item.created_at != null">
              {{ data.item.created_at | formatDate }}
            </span>
            <span v-else> N/A </span>
          </template>
          <!-- Column: status -->
          <template #cell(status)="data">
            <div v-if="checkPermission(['CHANGE_STATUS_JOBS', 'ALLOW_ALL'])">
              <b-form-checkbox
                v-model="data.item.status"
                switch
                :value="1"
                :unchecked-value="0"
                @change="updateStatusJob(data.item.id)"
              />
            </div>
            <div v-else>
              <span v-if="data.item.status === 1">
                {{ $t('generic.active') }}
              </span>
              <span v-else-if="data.item.status === 0">
                {{ $t('generic.inactive') }}
              </span>
              <span v-else>
                {{ $t('generic.removed') }}
              </span>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <edit-job-modal
              :job-data="data.item"
              :countries="countries"
              :club-info="clubInfo"
              :check-permission="checkPermission"
              :color-primary-btn="colorPrimaryBtn"
              @successfully="getJobs"
            />
          </template>
        </b-table>
      </b-overlay>
      <!-- pagination -->
      <app-paginator
        :data-list="paginate"
        @pagination-data="changePaginate"
      />
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import checkPermission from '@/auth/permissions'
import servicesCountry from '@/services/countryService'
import servicesJob from '@/services/jobService'
import FilterJob from './FilterJob.vue'
import CreateJobModal from './CreateJobModal.vue'
import EditJobModal from './EditJobModal.vue'
import AppPaginator from '../components/app-paginator/AppPaginator.vue'

export default {
  components: {
    FilterJob,
    CreateJobModal,
    EditJobModal,
    AppPaginator,
  },

  data() {
    return {
      isLoading: false,
      filters: {
        name: null,
        club_hash: null,
        country_hash: null,
        state_hash: null,
        status: null,
      },
      paginate: {
        from: 1,
        to: 15,
        total: 0,
      },
      jobList: [],
      countries: [],
      states: [],
      cities: [],
    }
  },

  computed: {
    ...mapGetters({
      role: 'role',
      job: 'jobPosition/job',
      corporateList: 'allClubs',
      clubInfo: 'clubInfo',
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),
    headerTableJob() {
      return [
        {
          key: 'name',
          label: this.$t('generic.name'),
          sortable: true,
        },
        // {
        //   key: 'country',
        //   label: this.$t('generic.country'),
        //   sortable: true,
        // },
        // {
        //   key: 'state',
        //   label: this.$t('generic.state'),
        //   sortable: true,
        // },
        {
          key: 'club_name',
          label: this.$t('corporate.club'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('generic.register'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('generic.status'),
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },

  watch: {
    'paginate.from': function () {
      this.getJobs()
    },
    'paginate.to': function () {
      this.getJobs()
    },
    'filters.name': function (name) {
      if (name != null || name !== '') this.getJobs()
    },
    'filters.club_hash': function (clubHash) {
      if (clubHash != null || clubHash !== undefined) this.getJobs()
    },
    'filters.country_hash': function (countryHash) {
      if (countryHash != null || countryHash !== undefined) this.getJobs()
    },
    'filters.state_hash': function (stateHash) {
      if (stateHash != null || stateHash !== undefined) this.getJobs()
    },
    'filters.status': function (status) {
      if (status != null) this.getJobs()
    },
  },

  mounted() {
    if (this.checkPermission(['VIEW_JOBS'])) {
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.filters.club_hash = this.clubInfo.hash
      }
      this.getJobs()
      this.fetchCountries()
      // this.fetchClubDataAll()
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },

  methods: {
    checkPermission,
    ...mapActions({
      // fetchCountries: 'fetchCountries',
      // getJobsData: 'jobPosition/getJobsData',
      // updateJob: 'jobPosition/updateJob',
      // fetchClubDataAll: 'fetchClubDataAll',
    }),
    fetchCountries() {
      servicesCountry.getCountries()
        .then(({ data }) => {
          this.countries = data.data.map(c => ({ id: c.hash, name: c.labelables[0].label }))
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    fetchStates() {
      servicesCountry.getStates(this.filtersObj)
        .then(({ data }) => {
          this.states = data.data.map(c => ({ id: c.hash, name: c.name }))
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    getJobsFilterReset() {
      this.filters.name = null
      this.filters.club_hash = null
      this.filters.country_hash = null
      this.filters.state_hash = null
      this.filters.status = null
      this.paginate.from = 1
      this.paginate.to = 15
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.filters.club_hash = this.clubInfo.hash
      }
      this.getJobs()
    },
    handleFilters(filterSelect) {
      if (filterSelect) {
        this.filters.name = filterSelect.search
        this.filters.club_hash = filterSelect.club_hash // || this.clubInfo.hash
        this.filters.country_hash = filterSelect.idCountry
        this.filters.state_hash = filterSelect.idState
        this.filters.status = filterSelect.status
      }
      this.getJobs()
    },
    changePaginate(paginateData) {
      this.paginate.to = paginateData.perPage
      this.paginate.from = paginateData.page
    },
    getJobs() {
      const params = {
        included: 'club',
        perPage: this.paginate.to,
        page: this.paginate.from,
      }
      this.isLoading = true
      servicesJob.getJobs(params, this.filters)
        .then(({ data }) => {
          this.paginate.total = data.total
          const list = data.data.map(e => {
            return {
              id: e.hash,
              name: e.name,
              club_id: e.club_hash,
              status: (e.status ? 1 : 0),
              created_at: e.created_at,
              club: e.club,
            }
          })
          this.jobList = list
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.responseCatch(error)
        })
    },
    updateStatusJob(jobData) {
      servicesJob.statusJob(jobData)
        .then(({ data }) => {
          this.getJobs()
        }).catch(error => {
          this.responseCatch(error)
        })
    },
  },
}
</script>
